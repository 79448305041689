body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.user-table tbody tr:hover{
  background: aliceblue;
  cursor: pointer;
}
.MuiDialog-paperWidthSm {
  max-width: 46.875vw !important;
}
.MuiDialog-paperFullWidth {
  width: 100% !important;
}
.MuiButton-root{
  font-size: 1.125vw !important;
}
.Dropdown-root {
  position: relative;
  
  margin-top: 1vw !important;
  font-size: 0.95vw !important;
  width: 32.34vw !important;

}
.Dropdown-control {
  border: none !important;
 
  outline: none !important;
  box-shadow: 6px 6px 20px #0000000f !important;
}
.fileContainer .chooseFileButton {

  background: #800e12 !important;
  
}
/* .MuiPaper-elevation0{
width: 20.313vw !important;
} */
.MuiTypography-body1 {
  font-size: 1.094vw !important;
 
}
.MuiToolbar-root MuiToolbar-regular MuiToolbar-gutters{
  width: auto !important;
}