.SearchInput {
  font-size: 1vw;
  height: 2.5vw;
  outline: none;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #eeeeee !important;
  border-radius: 0.5vw;
  padding-left: 1.4vw;
  width: 15vw;
  margin-top: 0.78vw;
}

.DropdownContainer {
  z-index: 5;
  position: absolute;
}
