/* .MuiDialog-paperFullWidth{
    background: transparent !important;
} */
.MuiFormGroup-root {
  flex-wrap: nowrap !important;
}

.brown {
  color: #a52038;
}

.red {
  color: red;
}

.radio-container .MuiFormGroup-root {
  flex-direction: row;
}

.border {
  margin: 2.5vw 0vw;
  background: #e2e2e2;
  height: 0.5px;
}

.title-text-small {
  font-size: 1.27vw;
  font-weight: 500;
}

.create-event .Dropdown-control,
.create-event .Dropdown-root {
  width: 100% !important;
}

.btn-primary {
  outline: none;
  border: 1px solid #800e12;
  cursor: pointer;
  margin-top: 1vw;
  color: #800e12;
  font-size: 0.9vw;
  background: white 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 15px #181d9233;
  border-radius: 0.54vw;
  opacity: 1;
  width: 9.5vw;
  height: 3.5vw;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  background-position: center;
  transition: background 0.8s;
}

.btn-primary:hover {
  background: #800e12 radial-gradient(circle, transparent 1%, #800e12 1%) center/15000%;
  color: white;
}
.btn-primary:active {
  background-color: #b5363b;
  background-size: 100%;
  transition: background 0s;
}
.btn-primary:disabled {
  opacity: 0.6;
}
