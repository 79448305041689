.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.my-sidebar {
  font-family: 'Poppins';
}

.my-sidebar .MuiListItem-gutters {
  padding-left: 1.17vw;
  padding-right: 1.17vw;
}

.my-sidebar .MuiListItem-gutters svg {
  width: 1.76vw;
  height: 1.76vw;
}

.my-sidebar .MuiListItem-root {
  padding: 0.88vw 2.05vw;
}

.my-sidebar .MuiListItemText-root {
  margin-top: 0.3vw;
  margin-bottom: 0.3vw;
}

.my-sidebar .MuiListItemText-primary {
  padding: 0 1.17vw;
}

.my-sidebar .MuiListItemText-dense span {
  font-size: 0.9vw;
}

.my-sidebar .MuiDrawer-paper > li:first-child {
  min-height: 4.07vw;
}

.sidebar-footer svg {
  color: #fff;
  opacity: 0.5;
  width: 1.76vw;
  height: 1.76vw;
}
.sidebar-footer .text {
  color: white;
  padding: 0 1.17vw !important;
  font-size: 1.094vw !important;
  line-height: 1.5;
  font-family: 'Poppins';
}
.sidebar-footer {
  padding: 0.78vw 2.05vw !important;
}

.sidebar-footer button {
  padding-bottom: 0.5vw;
}

.MuiTableCell-root {
  padding: 1.17vw !important;
}

.MuiFab-sizeSmall {
  width: 3vw !important;
  height: 3vw !important;
}

.MuiSvgIcon-root {
  font-size: 1.5vw !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* ..... */
.MuiFormControlLabel-labelPlacementTop {
  margin-left: 0.1vw !important;
  margin-top: 0.5vw !important;
  flex-direction: column-reverse;
}
/* .MuiFormControl-root {
  width: 100% !important;
} */

/*-----------DROPDOWN-----------*/
.Dropdown-control {
  margin-top: 12px;
  align-items: center !important;
  display: flex !important;
  font-size: 0.95vw !important;
  width: 31.8vw !important;
  height: 3vw !important;
  outline: none !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 6px 6px 20px #0000000f !important;
  border: 1px solid #eeeeee !important;
  border-radius: 0.5vw !important;
  padding-left: 3.3vw !important;
}

.Dropdown-menu {
  font-size: 0.95vw !important;
  width: 31.8vw !important;
}

.Dropdown-arrow {
  border-color: #800e12 transparent transparent !important;
  right: 1vw !important;
  top: 1.2vw !important;
}

.is-open .Dropdown-arrow {
  border-color: transparent transparent #800e12 !important;
}

.DropdownContainer .Dropdown-control {
  margin-top: unset !important;
  width: 7vw !important;
  padding-left: 2vw !important;
}

.DropdownContainer .Dropdown-menu {
  width: 7.5vw !important;
}

.MuiTableCell-root {
  font-size: 1vw !important;
}

.guest-table .MuiDialog-paperWidthSm {
  max-width: min-content !important;
}

.error_msg {
  font-size: 0.8vw;
  margin-top: 0.4vw;
  color: red;
  margin-left: 0.4vw;
}
.css-1s2u09g-control{
  width: 11.927vw !important;
  margin-top: .5vw !important;
}