.CreateEventForm {
  font-family: 'Poppins';
}

.CreateEventForm .Title {
  font-size: 2.7vw;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}

.CreateEventForm .FormContainer {
  margin-top: 0.5vw;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.CreateEventForm input {
  width: 100% !important;
}

.CreateEventForm .Inputs {
  margin-top: 1vw;
}

.CreateEventForm .Inputs > div {
  flex: 0.5;
}

.CostInputs {
  margin-top: 1vw;
}

.Label {
  font-size: 1.02vw;
  font-weight: 500;
  margin-top: 1vw;
}

.RadioContainer .MuiFormGroup-root {
  flex-direction: row !important;
}

.create-event .Dropdown-root {
  width: 100% !important;
}

.SubmitButton {
  margin-top: 1vw;
  display: flex;
  justify-content: flex-end;
}

.ActionButtons {
  flex: 0.1 1 0%;
  display: flex;
  align-items: flex-end;
  grid-column-gap: 0.2vw;
}

.ActionButtons div {
  padding: 0.2vw 0.71vw;
  background: #800e12;
  color: white;
  border-radius: 0.4vw;
  margin-bottom: 0.51vw;
  cursor: pointer;
}

.MapContainer .Title {
  font-size: 1.02vw;
  font-weight: 500;
  margin: 1vw 0;
}
.timeSelector{
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 1vw;
}