.Label {
  font-size: 1.02vw;
  font-weight: 500;
}

.error_msg {
  font-size: 0.8vw;
  margin-top: 0.4vw;
  color: red;
  margin-left: 0.4vw;
}

.eye {
  position: absolute;
  right: 0.5vw;
  margin-top: 0.8vw;
  cursor: pointer;
}

.InputContainer input {
  margin-top: 1vw;
  font-size: 0.95vw;
  width: 31.8vw;
  height: 3vw;
  outline: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #0000000f;
  border: 1px solid #eeeeee;
  border-radius: 0.5vw;
  padding-left: 3.3vw;
}

.InputContainer2 input {
  margin-top: 1vw;
  font-size: 0.95vw;
  width: 20.34vw;
  height: 3vw;
  outline: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #0000000f;
  border: 1px solid #eeeeee;
  border-radius: 0.5vw;
  padding-left: 3.3vw;
}
.InputContainer3 input {
  margin-top: 1vw;
  font-size: 0.95vw;
  width: 100%;
  height: 3vw;
  outline: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #0000000f;
  border: 1px solid #eeeeee;
  border-radius: 0.5vw;
  padding-left: 3.3vw;
}

.EventInput {
  display: flex;
  margin: 2vw;
  grid-column-gap: 1vw;
  align-items: center;
}

.EventInput input {
  font-size: 0.95vw;
  width: 100%;
  height: 3vw;
  outline: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #0000000f;
  border: 1px solid #eeeeee;
  border-radius: 0.5vw;
  padding-left: 1vw;
}

.CountryCode {
  position: absolute;
  margin-top: 1.3vw;
  left: 0.5vw;
  z-index: 1;
  font-size: 1vw;
  width: 2.56vw;
  height: 2.23vw;
  background: #800e12 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #0000000f;
  border-radius: 0.52vw;
  opacity: 1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PhoneInput {
  cursor: pointer;
  position: relative;
}

.PhoneInput input {
  width: 31.8vw;
  padding-left: 3.6vw;
  height: 3vw;
}

.TextArea > textarea {
  margin-top: 1vw;
  font-size: 0.95vw;
  width: 32.34vw;
  height: 10vw;
  outline: none;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 6px 6px 20px #0000000f;
  border: 1px solid #eeeeee;
  border-radius: 0.5vw;
  padding-left: 3.3vw;
  resize: none;
}

.PasswordContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.PasswordContainer i {
  font-size: 1vw;
  color: #800e12 !important;
  position: absolute;
  right: 0.5vw !important;

  margin-top: 0.8vw !important;
  cursor: pointer;
}

.RadioButtons {
  font-size: 0.95vw !important;
  margin-top: 0.2vw;
  margin-bottom: 0.5vw;
}

.RadioButtons .radioItem {
  display: inline-block;
  position: relative;
  padding: 0 6px;
}

.RadioButtons .radioItem input[type='radio'] {
  display: none;
}

.RadioButtons .radioItem label {
  color: #000000;
  font-size: 0.72vw;
  font-weight: normal;
  cursor: pointer;
}

.RadioButtons .radioItem label:before {
  content: ' ';
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 5px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 2px solid #800e12;
  background-color: transparent;
}

.RadioButtons .radioItem input[type='radio']:checked + label:after {
  border-radius: 11px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 9px;
  left: 10px;
  content: ' ';
  display: block;
  background: #800e12;
}

.DropdownWithState {
  display: flex;
  align-items: center;
  grid-column-gap: 0.5vw;
}

@media only screen and (max-width: 600px) {
  .Label {
    font-size: 3.2vw;
  }

  .InputContainer input {
    height: 11vw;
    width: 80vw !important;
    font-size: 2.8vw;
  }

  .TextArea > textarea {
    width: 80vw !important;
    height: 25vw;
  }

  .PhoneInput input {
    width: 69vw !important;
    padding-left: 14vw;
  }

  .CountryCode {
    border-radius: 2.13vw;
    height: 8.8vw;
    width: 11.2vw;
    font-size: 3.5vw;
    top: 1vw;
    left: 1vw;
  }
}
