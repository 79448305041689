.coupan .MuiFormControl-root {
  width: 31.8vw !important;
}

.coupan .MuiInputBase-input {
  font-size: 0.95vw !important;
  width: 31.8vw !important;
  height: 3vw !important;
  outline: none !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 6px 6px 20px #0000000f !important;
  border: 1px solid #eeeeee !important;
  border-radius: 0.5vw !important;
  padding-left: 3.3vw !important;
  margin-top: 1vw;
}

.coupan label {
  font-family: 'Poppins' !important;
  font-size: 1.3vw !important;
  font-weight: 500 !important;
  color: rgba(0, 0, 0, 0.87) !important;
  line-height: 1.43 !important;
}
