label + .MuiInput-formControl {
  width: 100% !important;
}

.dropdown-filter .Dropdown-root {
  margin-top: 0 !important;
  width: 9.34vw !important;
}

.dropdown-filter .Dropdown-control {
  margin-top: 0px !important;
  width: 9.8vw !important;
  padding-left: 1vw !important;
}

.reset-password-modal .MuiDialog-paperFullWidth {
  width: max-content !important;
}

.search-container {
  display: flex;
  grid-column-gap: 1vw;
}

.top-container {
  display: flex;
}

.top-container input {
  margin-top: 0;
  width: 15vw;
  padding-left: 1vw;
  margin-left: 2vw;
}
